<template>
  <div class="animations-view">
    <div class="animation-content">
      <LottieAnimation :animationData="animationData" :loop="true" :autoplay="true" />
      <div class="text-container">
        <p class="scrolling-text" ref="textElement">{{ currentPhrase }}</p>
      </div>
    </div>
  </div>
</template>

<script>
  import { gsap } from 'gsap';
  import LottieAnimation from "@/components/LottieAnimation.vue";
  import animationData from "@/assets/animations/scanning-animation-two.json";

  export default {
    name: "LottieAnimationView",
    components: {
      LottieAnimation,
    },
    data() {
      return {
        animationData,
        phrases: [
          "Scanning documents...",
          "Extracting key information...",
          "Processing resume data...",
          "Analyzing work experience...",
          "Identifying skills...",
          "Reading educational background...",
          "Converting text to data...",
          "OCR processing..."
        ],
        currentPhrase: "",
        currentIndex: 0,
      };
    },
    mounted() {
      this.initTextAnimation();
    },
    methods: {
      initTextAnimation() {
        const textElement = this.$refs.textElement;

        gsap.set(textElement, {
          x: '100%',
          opacity: 0
        });

        const animatePhrase = () => {
          this.currentPhrase = this.phrases[this.currentIndex];

          gsap.timeline()
              .to(textElement, {
                x: '0%',
                opacity: 1,
                duration: 1.5,
                ease: "back.out(1.7)",
              })
              .to(textElement, {
                delay: 0.8,
                x: '-100%',
                opacity: 0,
                duration: 1.2,
                ease: "elastic.in(1, 0.75)",
                onComplete: () => {
                  this.currentIndex = (this.currentIndex + 1) % this.phrases.length;
                  animatePhrase();
                },
              });
        };

        animatePhrase();
      }
    }
  };
</script>

<style scoped>
  .animations-view {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }

  .animation-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 600px;
  }

  .text-container {
    position: relative;
    width: 100%;
    height: 2.5em;
    display: flex;
    margin-top: 1rem;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }

  .scrolling-text {
    padding: 0 1rem;
    font-size: 1.9rem;
    font-weight: 550;
    color: #636b85;
    text-align: center;
    margin: 0;
    position: absolute;
    /*width: 100%;*/
    /*left: 0;*/
    /*top: 50%;*/
    /*transform: translateY(-50%);*/
    font-family: 'Rockwell', serif;
  }
</style>
