<template>
  <div ref="animationContainer" class="lottie-animation"></div>
</template>

<script>
import lottie from "lottie-web";

export default {
  name: "LottieAnimation",
  props: {
    animationData: {
      type: Object,
      required: true,
    },
    loop: {
      type: Boolean,
      default: true,
    },
    autoplay: {
      type: Boolean,
      default: true,
    },
  },

  mounted() {
    const animation = lottie.loadAnimation({
      container: this.$refs.animationContainer,
      animationData: this.animationData,
      loop: this.loop,
      autoplay: this.autoplay,
      renderer: "svg",
    });

    animation.addEventListener('DOMLoaded', () => {
      const durationInFrames = animation.totalFrames;
      const durationInSeconds = durationInFrames / animation.frameRate;
      this.$emit('animation-loaded', durationInSeconds);
    });
  },
};
</script>

<style scoped>
.lottie-animation {
  width: 100%;
  height: 100%;
}
</style>
